table {
  border-spacing: 1px;
  box-shadow: 0px 0px 0px 1px reduce(16) inset; }

tr:hover, tr:nth-child(even) td {
  background: reduce(6); }

th {
  background: reduce(10);
  text-align: inherit; }

th, td {
  box-shadow: 0px 0px 0px 1px reduce(16);
  padding: .5em 1em; }
