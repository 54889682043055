@function reduce ($percent) {
  @return rgba(mix(#282828, #fff), $percent / 100); }

$font-size: calc(0.8em + 0.25vw);
$font-weight: 300;
$heading-weight: 400;
$line-height: 1.65;
$link-color: #68f;
$background:  #ffffff;
$color:  #000000;

@import "basic";
@import "darkmode";
@import "layout";
@import "code";
@import "table";
@import "highlight";

body {
  display: flex;
  min-height: 100vh; }

h1 {
  font-size: 1.85em; }

table {
  width: 100%; }

header h1 {
  font-weight: inherit;
  margin: 0; }

article header {
  flex-direction: column;
  align-items: inherit; }

article header {
  margin-bottom: 1.5em; }

article header h1 {
  margin-bottom: 0.2em; }

article footer {
  padding: 1em 0; }




nav {
  margin: 0.5em 0; }

body > header h1 {
  width: 100%;
  line-height: 1.8;
  margin-bottom: 0.5em; }



